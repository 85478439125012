<template>
  <div class="ma-2 pa-4">
    <v-btn color="primary" @click="$router.push('/tenants/actions')" tile> Create</v-btn>
    <!-- <v-btn class="mx-2" color="primary" @click="getSharepointUrl" tile outlined> Sharepoint Login</v-btn> -->
    <v-layout row wrap class="mt-5" v-if="listOfTenants.length">
      <v-flex class="px-2" lg3 sm12 v-for="(item,index) in listOfTenants" :key=" 'mini' + index" @click="editHandler(item._id)">
        <social-widget mini v-bind="item"> </social-widget>
      </v-flex>
    </v-layout>
    <v-alert v-else color="cyan" border="left" elevation="2" colored-border icon="mdi-flash-outline" class="mt-3">
      <strong>No tenants found </strong>
    </v-alert>
  </div>
</template>
<script>
export default {
  data () {
    return {
      colors: [
        '#F44336',
        '#E91E63',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#2196F3',
        '#03A9F4',
        '#00BCD4',
        '#009688',
        '#4CAF50',
        '#8BC34A',
        '#CDDC39',
        '#FFEB3B',
        '#FFC107',
        '#FF9800',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B'
      ],
      avatars: [
        'https://avataaars.io/?avatarStyle=Transparent&topType=WinterHat4&accessoriesType=Prescription01&hatColor=Black&facialHairType=Blank&clotheType=GraphicShirt&clotheColor=Black&graphicType=Selena&eyeType=Squint&eyebrowType=AngryNatural&mouthType=Default&skinColor=DarkBrown',
        'https://avataaars.io/?avatarStyle=Transparent&topType=WinterHat1&accessoriesType=Sunglasses&hatColor=Red&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light',
        'https://avataaars.io/?avatarStyle=Transparent&topType=Hat&accessoriesType=Sunglasses&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Happy&eyebrowType=Default&mouthType=Default&skinColor=Light',
        'https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairSides&accessoriesType=Blank&hairColor=BrownDark&facialHairType=BeardMedium&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Gray01&eyeType=WinkWacky&eyebrowType=SadConcerned&mouthType=ScreamOpen&skinColor=Brown',
      ],
      listOfTenants: []
    }
  },
  components: {
    'social-widget': () => import ('@/components/SocialWidget.vue')
  },
  mounted () {
    window.onmessage = ({ data } = {}) => {
      if (data && data.code) {
        this.loginHandler(data.code)
      }
    }
    this.getListHandler()
  },
  methods: {
    getSharepointUrl () {
      this.$_execute('get', 'microsoft/get-authurl')
        .then(({ data }) => {
          window.open(data, '_blank', 'left=100, top=140, toolbar=yes, status=yes, menubar=yes, scrollbars=no, resizable=yes, copyhistory=no, height=400, width=500')
        })
    },
    loginHandler (code) {
      this.$_execute('post', 'microsoft/validate-user', { code })
    },
    getListHandler () {
      this.$_execute('get', 'tenants').then(({data}) => {
        data.forEach(item => {
          item.name = item.tenant,
          item.caption = item.domain,
          item.avatar = this.avatars[Math.floor(Math.random()*this.avatars.length)],
          item.color = this.colors[Math.floor(Math.random()*this.colors.length)]
        });
        this.listOfTenants = data
      })
    },
    editHandler (id) {
      this.$router.push(`/tenants/actions/${id}`)
    },
  }
}
</script>
<style scoped>
.widget-card {
    border-radius: 5%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
    background-color: transparent;
  }
</style>
